// src/pages/Login/LoginPage.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, CssBaseline, Box, Typography, TextField, Button, Avatar, Paper } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import socket from "../../../socket/Socket.js";
import { store } from "../../../store/store.js";

const LoginPage = ({ setAuthenticated }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();

	useEffect(() => {
		console.log(process.env.REACT_APP_API_URL);
		const checkAuth = async () => {
			const token = localStorage.getItem("token");
			if (token && !token.startsWith("token-")) {
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_API_URL}/protectedRoute`,
						{},
						{
							headers: { Authorization: `Bearer ${token}` },
						}
					);
					if (response.status === 200) {
						setAuthenticated(true);

						history.push("/chart");
					}
				} catch (error) {
					console.error("Error during authentication:", error);
					setAuthenticated(false);
				}
			}
		};

		checkAuth();
	}, [setAuthenticated, history]);

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			console.log("LOGIN");
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/loginT`, {
				email,
				password,
			});

			console.log("RESPONSE", response);

			if (response.status === 200) {
				const { encryptedData, token, user, demoAccount } = response.data;
				localStorage.setItem("token", token);
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("encryptedUserData", encryptedData);
				localStorage.setItem("selectedAccount", demoAccount);

				const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
				const setBalance = store.getState().setBalance;
				setBalance(selectedAccount.balance);
				console.log("SELECTED ACCOUNT", selectedAccount);

				setAuthenticated(true);
				const loginData = {
					userId: selectedAccount.userId,
					accountId: selectedAccount.accountId,
					token: token,
				};

				socket.emit("login", loginData);
				history.push("/chart");
			} else {
				toast.error(response.data.message || "Error during login");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "An error occurred. Please try again.");
		}
	};

	const handleForgotPassword = async () => {
		try {
			if (email === "") {
				toast.error("Por favor escribe tu correo electrónico");
				return;
			}
			const response = await axios.post(process.env.REACT_APP_API_URL + "/forgot", {
				email: email,
			});
			if (response.status === 200) {
				toast.success("Contraseña Temporal Enviada");
			} else {
				toast.error(response.data.message || "Error al enviar correo");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "Un error ha ocurrido. Por favor intenta de nuevo.");
		}
	};
	return (
		<>
			<Box
				sx={{
					backgroundImage: 'url("/assets/bg1.jpg")',
					backgroundSize: "cover",
					backgroundPosition: { xs: "30% center", md: "center center" },
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
					"&::before": {
						content: '""',
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
						display: { xs: "block", md: "none" }, // Only show on mobile
					},
				}}>
				<CssBaseline />
				<Container component="main" maxWidth="xs">
					<Box
						sx={{
							position: { xs: "static", md: "absolute" },
							left: { xs: "auto", md: "60%" },
							top: { xs: "auto", md: "50%" },
							transform: { xs: "none", md: "translate(-90%, -50%)" },
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: { xs: 2, md: 0 },
							width: "100%",
						}}>
						<Box
							sx={{
								position: { xs: "static", md: "absolute" },
								// top: { md: "-10" },
								left: { md: "47%" },
								width: "100%",
								maxWidth: "150px",
								zIndex: 1,
							}}>
							<img src="/assets/logo.png" alt="Login" width={"100%"} height="auto" />
						</Box>
						<Typography
							component="h1"
							variant="h3"
							sx={{
								marginTop: { md: 18 },
								fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
								color: "#fff",
								fontWeight: "bold",
								textAlign: "center",
							}}>
							Welcome Back
						</Typography>
						<Box
							component="form"
							onSubmit={handleLogin}
							sx={{
								mt: 1,
								width: "100%",
								maxWidth: { md: "400px" },
								padding: { xs: 2, md: 4 },
							}}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="E-mail"
								name="email"
								autoComplete="email"
								autoFocus
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type="password"
								id="password"
								autoComplete="current-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									backgroundColor: "#4571bc",
									color: "#000",
									borderColor: "#4571bc",
									"&:hover": {
										backgroundColor: "#425789",
									},
									border: "1px solid",
								}}>
								Iniciar
							</Button>
							<Typography align="center">
								<Link to="#" onClick={handleForgotPassword} style={{ color: "#fff" }}>
									¿Olvidaste tu contraseña?
								</Link>
							</Typography>
							<Button
								fullWidth
								variant="outlined"
								sx={{
									mt: 2,
									mb: 2,
									color: "#4571bc",
									borderColor: "#4571bc",
									"&:hover": {
										backgroundColor: "#2c2c2c",
										borderColor: "#425789",
										color: "#425789",
									},
								}}
								onClick={() => history.push("/register")}>
								Registrarse
							</Button>
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default LoginPage;
