// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#4571bc", // Color principal para botones y otros componentes
		},
		secondary: {
			main: "#425789", // Color secundario
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					border: "1px solid #4571bc", // Borde del mismo color que el fondo
					"&:hover": {
						backgroundColor: "#425789", // Cambia el color al hacer hover
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& .MuiInputBase-input": {
						color: "#ffffff", // Color del texto
						backgroundColor: "#07080a", // Color de fondo
					},
					"& .Mui-focused .MuiInputBase-input": {
						backgroundColor: "#07080a", // Color de fondo al enfocar
					},
					"& .MuiInput-underline:before": {
						borderBottomColor: "#4571bc", // Color del borde inferior
					},
					"&:hover .MuiInput-underline:before": {
						borderBottomColor: "#4571bc", // Cambia el color del borde al hacer hover
					},
					"& .MuiInputLabel-root": {
						color: "#ffffff",
						fontSize: "1rem",
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					color: "#ffffff", // Color del texto dentro del Select
					backgroundColor: "#07080a", // Color de fondo del Select
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#4571bc", // Color del borde del Select
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "#4571bc", // Cambia el color del borde al hacer hover
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#425789", // Cambia el color del borde al enfocar
					},
				},
				icon: {
					color: "#ffffff", // Color del icono del desplegable
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: "#ffffff", // Color del texto en las opciones
					backgroundColor: "#07080a", // Color de fondo en las opciones
					"&:hover": {
						backgroundColor: "#2c2c2c", // Cambia el color de fondo al hacer hover
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#ffffff", // Color del texto de la etiqueta
					"&.Mui-focused": {
						color: "#425789", // Color de la etiqueta al enfocar
					},
				},
			},
		},
	},
});

export default theme;
