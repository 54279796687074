// src/pages/Register/RegisterPage.jsx

import React, { useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { Container, CssBaseline, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import Modal from "@mui/material/Modal";
import { QRCodeCanvas } from "qrcode.react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const RegisterPage = () => {
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		country: "",
		password: "",
		confirmPassword: "",
		identityFile: null,
	});
	const [isImageUploaded, setIsImageUploaded] = useState(false);
	const [errors, setErrors] = useState({});
	const history = useHistory();
	const [openPaymentModal, setOpenPaymentModal] = useState(false);
	const [cryptoPaymentInfo, setCryptoPaymentInfo] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { name, value, files } = e.target;
		if (name === "identityFile") {
			setFormData((prev) => ({ ...prev, [name]: files[0] }));
			setIsImageUploaded(files.length > 0); // Set state based on file upload
		} else {
			setFormData((prev) => ({ ...prev, [name]: value }));
		}
	};

	const validate = () => {
		let tempErrors = {};
		if (!formData.fullName) tempErrors.fullName = "Nombre completo es requerido.";
		if (!formData.email) tempErrors.email = "Correo electrónico es requerido.";
		else if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Correo electrónico no es válido.";
		if (!formData.country) tempErrors.country = "País es requerido.";
		if (!formData.password) {
			tempErrors.password = "Contraseña es requerida.";
		} else if (formData.password.length < 6) {
			tempErrors.password = "La contraseña debe tener al menos 6 caracteres.";
		} else if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/.test(formData.password)) {
			tempErrors.password = "La contraseña debe contener al menos una mayúscula, un número y un símbolo.";
		}
		if (!formData.confirmPassword) tempErrors.confirmPassword = "Por favor confirme su contraseña.";
		else if (formData.password !== formData.confirmPassword) tempErrors.confirmPassword = "Las contraseñas no coinciden.";
		if (!formData.identityFile) tempErrors.identityFile = "Por favor suba una identificación.";

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handlePayment = async (userId) => {
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));

		const payload = {
			customerId: userId,
			buyTo: "Trade ChampionShip League",
			service: "tournament",
			description: "Registro Torneo",
			amount: "12",
			currency: "USDT TRC20",
			frequency: "Único",
			orderId: `ORD4-${Date.now()}-${formData.email.split("@")[0].substring(0, 6)}`,
			extra: selectedAccount.userId,
		};
		console.log("PAYLOAD", payload);
		try {
			const paymentResponse = await axios.post(`${process.env.REACT_APP_API_URL}/purchaseCrypto`, payload);
			console.log("RESP", paymentResponse);

			if (paymentResponse.data.ok) {
				setCryptoPaymentInfo(paymentResponse.data.data);
				setOpenPaymentModal(true);
				setLoading(false);
			} else {
				toast.error(paymentResponse.data.message);
				setLoading(false);
			}
		} catch (error) {
			toast.error("Error al procesar el pago");
			setLoading(false);
		}
	};

	const handleRegister = async (e) => {
		e.preventDefault();
		if (!validate()) {
			return;
		}
		setLoading(true);

		try {
			const formDataObj = new FormData();
			for (const key in formData) {
				formDataObj.append(key, formData[key]);
			}
			const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
			formDataObj.append("extra", selectedAccount.userId);

			const response = await axios.post(`${process.env.REACT_APP_API_URL}/registerT`, formDataObj, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.data.ok) {
				toast.success(response.data.message);
				handlePayment(response.data.userId);
			} else {
				if (response.data.status === 401) {
					setCryptoPaymentInfo(response.data.data);
					setOpenPaymentModal(true);
					setLoading(false);
				}

				toast.error(response.data.message || "Error durante el registro");
			}
		} catch (error) {}
	};

	return (
		<>
			<Box
				sx={{
					backgroundImage: 'url("/assets/bg.jpg")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Box
					sx={{
						position: "absolute",
						right: 0,
						top: "50%",
						transform: "translateY(-50%)",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						padding: "20px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					}}>
					<CssBaseline />
					<Container component="main" maxWidth="xs">
						<Box
							sx={{
								marginTop: 8,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<Box
								sx={{
									// position: { xs: "static", md: "absolute" },
									// // top: { md: "-10" },
									// left: { md: "47%" },
									width: "100%",
									maxWidth: "150px",
									zIndex: 1,
								}}>
								<img src="/assets/logo.png" alt="Login" width={"100%"} height="auto" />
							</Box>
							<Typography component="h1" variant="h5" sx={{ color: "#4571bc" }}>
								Registrarse
							</Typography>
							<Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="fullName"
									label="Nombre Completo"
									name="fullName"
									autoComplete="name"
									autoFocus
									value={formData.fullName}
									onChange={handleChange}
									error={!!errors.fullName}
									helperText={errors.fullName}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Correo Electrónico"
									name="email"
									autoComplete="email"
									value={formData.email}
									onChange={handleChange}
									error={!!errors.email}
									helperText={errors.email}
								/>
								<FormControl fullWidth margin="normal" error={!!errors.country}>
									<InputLabel id="country-label">País</InputLabel>
									<Select
										labelId="country-label"
										id="country"
										name="country"
										value={formData.country}
										onChange={handleChange}
										displayEmpty>
										<MenuItem value="">
											<em>Selecciona tu país</em>
										</MenuItem>
										<MenuItem value="argentina">Argentina</MenuItem>
										<MenuItem value="bolivia">Bolivia</MenuItem>
										<MenuItem value="brasil">Brasil</MenuItem>
										<MenuItem value="chile">Chile</MenuItem>
										<MenuItem value="colombia">Colombia</MenuItem>
										<MenuItem value="costaRica">Costa Rica</MenuItem>
										<MenuItem value="cuba">Cuba</MenuItem>
										<MenuItem value="ecuador">Ecuador</MenuItem>
										<MenuItem value="salvador">El Salvador</MenuItem>
										<MenuItem value="guatemala">Guatemala</MenuItem>
										<MenuItem value="haiti">Haití</MenuItem>
										<MenuItem value="honduras">Honduras</MenuItem>
										<MenuItem value="mexico">México</MenuItem>
										<MenuItem value="nicaragua">Nicaragua</MenuItem>
										<MenuItem value="panama">Panamá</MenuItem>
										<MenuItem value="paraguay">Paraguay</MenuItem>
										<MenuItem value="peru">Perú</MenuItem>
										<MenuItem value="republicaDominicana">República Dominicana</MenuItem>
										<MenuItem value="uruguay">Uruguay</MenuItem>
										<MenuItem value="venezuela">Venezuela</MenuItem>
									</Select>
									{errors.country && <FormHelperText>{errors.country}</FormHelperText>}
								</FormControl>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
									autoComplete="new-password"
									value={formData.password}
									onChange={handleChange}
									error={!!errors.password}
									helperText={errors.password || "Debe contener al menos 6 caracteres, una mayúscula, un número y un símbolo"}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="confirmPassword"
									label="Verificar Contraseña"
									type="password"
									id="confirmPassword"
									autoComplete="new-password"
									value={formData.confirmPassword}
									onChange={handleChange}
									error={!!errors.confirmPassword}
									helperText={errors.confirmPassword}
								/>
								<input
									accept="image/*"
									style={{ display: "none" }}
									id="raised-button-file"
									type="file"
									name="identityFile"
									onChange={handleChange}
								/>
								<label htmlFor="raised-button-file">
									<Button
										fullWidth
										variant="outlined"
										component="span"
										sx={{
											mt: 2,
											mb: 2,
											backgroundColor: isImageUploaded ? "#4571bc" : "initial", // Change color if image uploaded
											color: isImageUploaded ? "#fff" : "#fff",
											"&:hover": {
												backgroundColor: isImageUploaded ? "#4571bc" : "initial",
											},
										}}>
										{isImageUploaded ? "Imagen Cargada" : "Subir Identificación"}
									</Button>
								</label>
								{errors.identityFile && <Typography color="error">{errors.identityFile}</Typography>}
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										mt: 3,
										mb: 2,
										backgroundColor: "#4571bc",
										color: "#000",
										"&:hover": {
											backgroundColor: "#425789",
										},
									}}>
									Registrar
								</Button>
								<Typography align="center">
									<Link to="/" style={{ color: "#fff" }}>
										¿Ya tienes cuenta? Inicia sesión
									</Link>
								</Typography>
							</Box>
						</Box>
					</Container>
				</Box>
			</Box>

			<Modal
				open={openPaymentModal}
				onClose={() => setOpenPaymentModal(false)}
				aria-labelledby="payment-modal"
				aria-describedby="crypto-payment-information">
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "20%",
						bgcolor: "#1a1a1a",
						border: "2px solid #4571bc",
						borderRadius: 2,
						boxShadow: 24,
						p: 4,
					}}>
					<Box sx={{ textAlign: "center", mt: 2 }}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								mb: 2,
								boxShadow: "0 0 10px #4599d9",
								padding: "10px",
								borderRadius: "8px",
							}}>
							<img src="/assets/mPayLogo.png" alt="MPay Logo" style={{ height: "40px", marginRight: "10px" }} />
							<Typography variant="h5" sx={{ color: "#fff", fontFamily: "'Orbitron', sans-serif" }}>
								MPay Crypto
							</Typography>
						</Box>

						{cryptoPaymentInfo && (
							<>
								<Typography sx={{ color: "#fff", mb: 1 }}>
									Enviar {cryptoPaymentInfo.amount} {cryptoPaymentInfo.currency} a la dirección:
								</Typography>
								<Typography sx={{ color: "#fff", mb: 2, wordBreak: "break-all" }}>{cryptoPaymentInfo.address}</Typography>
								<QRCodeCanvas value={cryptoPaymentInfo.address} size={256} />
								<Typography sx={{ color: "#fff", mt: 2 }}>ID del Pedido: {cryptoPaymentInfo.orderId}</Typography>
								<Typography sx={{ color: "#fff" }}>Descripción: {cryptoPaymentInfo.description}</Typography>
								<Typography sx={{ color: "#fff" }}>Frecuencia: {cryptoPaymentInfo.frequency}</Typography>
								<Typography sx={{ color: "#fff" }}>Estado: {cryptoPaymentInfo.message}</Typography>
							</>
						)}
					</Box>

					<Typography
						sx={{
							color: "#ff9800",
							mt: 3,
							fontWeight: "bold",
							textAlign: "center",
							fontSize: "0.9rem",
							padding: "10px",
							border: "1px solid #ff9800",
							borderRadius: "4px",
						}}>
						Por favor espere y no cierre esta ventana mientras realiza la transferencia. Tienes 30 mins para realizarla.
					</Typography>
				</Box>
			</Modal>
		</>
	);
};

export default RegisterPage;
