import { io } from "socket.io-client";

const serverPath = "https://api.tradechampionshipleague.com:12002";

const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
const token = localStorage.getItem("token");

const query = { version: "V1.0.1" };
if (selectedAccount != null) {
	query.userId = selectedAccount.userId;
}
if (token != null) {
	query.token = token;
}

const socket = io(serverPath, {
	transports: ["websocket"],
	reconnection: true,
	reconnectionAttempts: 5,
	reconnectionDelay: 2000,
	query: query,
});

const connectionStatus = document.getElementById("socketAPI");

let popup;

function showReconnectPopup() {
	if (!popup) {
		popup = document.createElement("div");
		popup.style.position = "fixed";
		popup.style.top = "50%";
		popup.style.left = "50%";
		popup.style.transform = "translate(-50%, -50%)";
		popup.style.padding = "20px";
		popup.style.backgroundColor = "#333";
		popup.style.color = "white";
		popup.style.border = "1px solid #555";
		popup.style.borderRadius = "10px";
		popup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
		popup.style.zIndex = "9999";
		popup.style.textAlign = "center";

		const message = document.createElement("p");
		message.textContent = "Tienes problemas de Conexion y la conexión se ha perdido. ¿Desea reconectar?";
		message.style.marginBottom = "20px";
		popup.appendChild(message);

		const reconnectButton = document.createElement("button");
		reconnectButton.textContent = "Reconectar";
		reconnectButton.style.backgroundColor = "#555";
		reconnectButton.style.color = "white";
		reconnectButton.style.border = "none";
		reconnectButton.style.padding = "10px 20px";
		reconnectButton.style.borderRadius = "5px";
		reconnectButton.style.cursor = "pointer";
		reconnectButton.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
		reconnectButton.style.display = "inline-block";
		reconnectButton.onclick = () => {
			window.location.reload();
		};
		popup.appendChild(reconnectButton);

		document.body.appendChild(popup);
	}
}

function hideReconnectPopup() {
	if (popup) {
		popup.remove();
		popup = null;
	}
}

socket.on("connect", () => {
	connectionStatus.style.backgroundColor = "green";
	hideReconnectPopup();
});

socket.on("disconnect", (reason) => {
	connectionStatus.style.backgroundColor = "red";
	showReconnectPopup();
});

socket.on("close", (reason) => {
	connectionStatus.style.backgroundColor = "red";
	showReconnectPopup();
});

socket.on("error", (error) => {
	connectionStatus.style.backgroundColor = "red";
	showReconnectPopup();
});

import JSConfetti from "js-confetti";
import { gsap } from "gsap";

const jsConfetti = new JSConfetti();

socket.on("temporaryConnection", (data) => {
	const { tempId, message } = data;

	localStorage.setItem(
		"selectedAccount",
		JSON.stringify({
			userId: tempId,
			accountId: `account-${tempId}`,
		})
	);

	if (message === "Pago Exitoso") {
		showEpicWelcome();

		setTimeout(() => {
			window.location.href = "/login";
		}, 5000);
	} else {
		console.log(message);
	}
});

function showEpicWelcome() {
	// Confeti dinámico
	jsConfetti.addConfetti({
		emojis: ["🎉", "🎊", "🔥", "🏆", "💎"],
		emojiSize: 50,
		confettiNumber: 100,
	});

	// Contenedor para el mensaje
	const animationContainer = document.createElement("div");
	animationContainer.style.position = "fixed";
	animationContainer.style.top = "50%";
	animationContainer.style.left = "50%";
	animationContainer.style.transform = "translate(-50%, -50%)";
	animationContainer.style.zIndex = "9999";
	animationContainer.style.textAlign = "center";
	animationContainer.style.color = "#FFF";
	animationContainer.style.fontFamily = "'Poppins', sans-serif";
	animationContainer.style.fontWeight = "bold";

	// Título principal
	const title = document.createElement("h1");
	title.textContent = "¡Bienvenido al Torneo!";
	title.style.fontSize = "4rem";
	title.style.textShadow = "0 0 20px #FFD700, 0 0 40px #FF4500";

	// Subtítulo
	const subtitle = document.createElement("p");
	subtitle.textContent = "El escenario está listo para tu éxito.";
	subtitle.style.fontSize = "1.5rem";
	subtitle.style.marginTop = "10px";

	animationContainer.appendChild(title);
	animationContainer.appendChild(subtitle);
	document.body.appendChild(animationContainer);

	// Animaciones con GSAP
	gsap.fromTo(
		animationContainer,
		{ opacity: 0, scale: 0 },
		{
			opacity: 1,
			scale: 1,
			duration: 1.5,
			ease: "elastic.out(1, 0.3)",
		}
	);

	gsap.to(animationContainer, {
		opacity: 0,
		scale: 0.5,
		duration: 1,
		delay: 4,
		ease: "power2.in",
		onComplete: () => animationContainer.remove(),
	});
}

const style = document.createElement("style");
style.textContent = `
	@keyframes explode {
		0% {
			transform: scale(1);
			opacity: 1;
		}
		100% {
			transform: scale(3);
			opacity: 0;
		}
	}
`;
document.head.appendChild(style);

document.addEventListener("visibilitychange", async () => {
	if (document.hidden) {
		// socket.disconnect();
	} else {
		const selectedAccount = await JSON.parse(localStorage.getItem("selectedAccount"));
		const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
		const token = await localStorage.getItem("token");

		if (symbolInfo) {
			let allPos = {
				symbol: symbolInfo.symbol,
				userId: selectedAccount.userId,
				accountId: selectedAccount.accountId,
			};

			socket.emit("allLines", allPos);
		}

		if (!socket.connected && selectedAccount && token) {
			socket.io.opts.query = {
				version: "V1.0.1",
				userId: selectedAccount.userId,
				token: token,
			};
			socket.connect();
		} else {
			hideReconnectPopup();
		}
	}
});

export default socket;
