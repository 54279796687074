import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { store } from "../../../../store/store";
import socket from "../../../../socket/Socket.js";
import axios from "axios";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

export const AccountsPage = ({ onHide }) => {
	const initialActiveAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const [activeAccount, setActiveAccount] = useState(initialActiveAccount);
	const [accounts, setAccounts] = useState([]);
	const [isVisible, setIsVisible] = useState(true);
	const [showCreditCardForm, setShowCreditCardForm] = useState(false);
	const [cardDetails, setCardDetails] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focused: "",
	});

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (isVisible) {
			getAccounts();
		}
	}, [isVisible]);

	const getAccounts = async () => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/getList2`, {
				table: "accounts",
				userId: initialActiveAccount.userId,
			});
			console.log("ACCOUNTS", response.data);
			localStorage.setItem("accounts", JSON.stringify(response.data));
			setAccounts(response.data);
		} catch (error) {
			if (error.message === "Network Error") {
				toast.error("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
			} else {
				toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
			}
		}
	};

	const handleAccountSelection = (account) => {
		if (account.status === "0" || Number(account.balance) <= 0) {
			toast.error("Esta cuenta está desactivada o no tiene fondos");
			return;
		}

		if (account.status === "2") {
			setShowCreditCardForm(true);
			return;
		}

		const combinedAccount = {
			...activeAccount,
			...account,
			accountId: account.id,
		};
		delete combinedAccount.id;

		const setBalance = store.getState().setBalance;
		setBalance(Number(combinedAccount.balance).toFixed(2));
		delete combinedAccount.balance;

		const data = { userId: combinedAccount.userId, accountId: combinedAccount.accountId };
		socket.emit("allLines", data);

		const setSelectedAccount = store.getState().setSelectedAccount;
		setSelectedAccount(combinedAccount);
		localStorage.setItem("selectedAccount", JSON.stringify(combinedAccount));
		setActiveAccount(combinedAccount);
		onHide(false);
	};

	const handlePaymentSuccess = async () => {
		try {
			// Call the API after successful payment
			await axios.post(`${process.env.REACT_APP_API_URL}/paymentSuccess`, {
				userId: activeAccount.userId,
				amount: 50,
			});
			toast.success("Pago realizado con éxito.");
			setShowCreditCardForm(false);
		} catch (error) {
			toast.error("Error al procesar el pago. Por favor, intenta nuevamente más tarde.");
		}
	};

	const handleInputChange = (e) => {
		setCardDetails({ ...cardDetails, [e.target.name]: e.target.value });
	};

	const handleInputFocus = (e) => {
		setCardDetails({ ...cardDetails, focused: e.target.name });
	};

	const handleSubmitPayment = () => {
		const payload = {
			userId: user.id,
			amount: selectedPurchaseOption.price,
			chartType: selectedPurchaseOption.key,
			cardDetails: cardDetails,
		};
		axios
			.post("https://mitserver.app:9005/purchase", payload)
			.then((response) => {
				if (response.status === 200) {
					toast.success("Pago realizado con éxito", {
						autoClose: 2000,
						theme: "dark",
					});

					// Actualizar el localStorage del usuario
					const storedUser = JSON.parse(localStorage.getItem("user"));
					if (storedUser) {
						let paidOptions = [];
						if (storedUser.paidOptions) {
							paidOptions = JSON.parse(storedUser.paidOptions);
						}
						paidOptions.push(selectedPurchaseOption.key);
						storedUser.paidOptions = JSON.stringify(paidOptions);
						localStorage.setItem("user", JSON.stringify(storedUser));
					}
					handleSelectOption(selectedPurchaseOption.key);
					setShowPaymentInputs(false);
				}
			})
			.catch((error) => {
				toast.error("Error en el pago", {
					autoClose: 2000,
					theme: "dark",
				});
			});
	};

	return (
		<div>
			<Typography sx={{ fontSize: 14, p: 1 }} color="text.secondary" gutterBottom>
				Cuentas
			</Typography>
			<Grid container spacing={1}>
				{accounts &&
					accounts.map((account, index) => {
						const isActiveAccount = activeAccount?.accountId === account.id;
						return (
							<Grid item xs={6} sm={6} md={6} lg={6} xxl={6} key={index}>
								<div className="cart-container" style={{ maxWidth: 345 }}>
									<img
										src="/assets/trofeo.png"
										alt="Tournament"
										style={{ width: "80%", marginBottom: "10px", borderRadius: "50%" }}
									/>
									<h2 className="cart-container-title">${account.balance}</h2>
									<p className="container-description cart-capitalize">{account.type}</p>
									<Button
										onClick={() => handleAccountSelection(account)}
										sx={{
											backgroundColor: isActiveAccount ? "#4571bc" : "#425789",
											color: isActiveAccount ? "#000" : "#000",
											border: isActiveAccount ? "1px solid #4571bc" : "1px solid #ccc",
											borderRadius: "15px",
											fontWeight: "bold",
											padding: "10px",
											cursor: "pointer",
											"&:hover": {
												backgroundColor: isActiveAccount ? "#4571bc" : "#425789",
											},
										}}>
										{account.status === "2" ? "Comprar" : isActiveAccount ? "Activa" : "Seleccionar"}
									</Button>
									C-{account.id}
								</div>
							</Grid>
						);
					})}
			</Grid>
			{showCreditCardForm && (
				<div style={{}}>
					<Typography
						sx={{ fontFamily: "'Orbitron', sans-serif", fontSize: 12, p: 1, textAlign: "center" }}
						color="text.secondary"
						gutterBottom>
						Torneo de <span style={{ fontWeight: "bold", color: "white" }}>$50</span>
						{/* con<span style={{ fontWeight: "bold", color: "white" }}> $500</span> para operar */}
					</Typography>
					<Cards
						number={cardDetails.number}
						name={cardDetails.name}
						expiry={cardDetails.expiry}
						cvc={cardDetails.cvc}
						focused={cardDetails.focused}
					/>
					<TextField
						label="Número de Tarjeta"
						variant="outlined"
						fullWidth
						margin="dense"
						name="number"
						value={cardDetails.number}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<TextField
						label="Nombre en la Tarjeta"
						variant="outlined"
						fullWidth
						margin="dense"
						name="name"
						value={cardDetails.name}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<TextField
						label="Fecha de Expiración"
						variant="outlined"
						fullWidth
						margin="dense"
						name="expiry"
						value={cardDetails.expiry}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<TextField
						label="CVV"
						variant="outlined"
						fullWidth
						margin="dense"
						name="cvc"
						value={cardDetails.cvc}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						InputProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
						InputLabelProps={{
							style: { color: "#fff", fontSize: "0.8rem" },
						}}
					/>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={handleSubmitPayment}
						sx={{
							marginTop: 2,
							backgroundColor: "#4571bc",
							color: "#fff",
							fontWeight: "bold",
							fontFamily: "'Orbitron', sans-serif",
							padding: "8px 16px",
							fontSize: "0.8rem",
							cursor: "pointer",
							transition: "all 0.3s ease",
							textShadow: "0 0 10px #fff",
							boxShadow: "0 0 5px #4571bc, 0 0 10px #4571bc",
							"&:hover": {
								backgroundColor: "#425789",
								boxShadow: "0 0 5px #4571bc, 0 0 10px #4571bc",
							},
						}}>
						Pagar
					</Button>
				</div>
			)}
		</div>
	);
};
